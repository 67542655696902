var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const Instagram = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", role: "presentation", focusable: "false" }, props),
        React.createElement("path", { fill: "#000", d: "M11.996 4.622c2.404 0 2.686.01 3.636.053.879.04 1.354.186 1.672.311.421.164.721.357 1.035.672.315.314.511.614.672 1.036.121.318.271.793.31 1.671.043.95.054 1.233.054 3.637s-.01 2.686-.054 3.636c-.039.879-.185 1.354-.31 1.672a2.8 2.8 0 0 1-.672 1.036 2.75 2.75 0 0 1-1.035.671c-.318.122-.793.272-1.672.311-.95.043-1.232.054-3.636.054s-2.685-.01-3.635-.054c-.879-.04-1.354-.186-1.672-.31a2.8 2.8 0 0 1-1.035-.672 2.75 2.75 0 0 1-.672-1.036c-.121-.318-.271-.793-.31-1.672-.043-.95-.054-1.232-.054-3.636s.01-2.686.053-3.637c.04-.878.186-1.353.311-1.671.164-.422.357-.722.672-1.036.314-.315.614-.511 1.035-.672.318-.121.793-.271 1.672-.31.95-.047 1.232-.054 3.635-.054m0-1.622c-2.442 0-2.75.01-3.71.054-.957.042-1.611.196-2.182.418A4.4 4.4 0 0 0 4.51 4.51c-.5.5-.807 1.004-1.04 1.593-.221.572-.375 1.225-.417 2.186C3.01 9.248 3 9.555 3 11.998s.01 2.75.054 3.712c.042.957.196 1.61.417 2.186.229.593.54 1.093 1.04 1.593s1.003.807 1.593 1.04c.571.221 1.225.375 2.185.417C9.25 20.99 9.554 21 12 21s2.75-.01 3.71-.054c.958-.042 1.611-.196 2.186-.418a4.4 4.4 0 0 0 1.593-1.039c.5-.5.807-1.004 1.04-1.593.221-.572.375-1.225.417-2.186.043-.961.054-1.265.054-3.712s-.01-2.75-.054-3.711c-.042-.958-.196-1.611-.417-2.186a4.4 4.4 0 0 0-1.04-1.594c-.5-.5-1.003-.807-1.593-1.039-.571-.222-1.225-.375-2.185-.418-.965-.04-1.272-.05-3.715-.05" }),
        React.createElement("path", { fill: "#000", d: "M11.996 7.376a4.623 4.623 0 0 0 0 9.245 4.625 4.625 0 0 0 4.622-4.623 4.625 4.625 0 0 0-4.622-4.622m0 7.623a3 3 0 1 1 0-6 3 3 0 0 1 0 6M16.8 8.272a1.079 1.079 0 1 0 0-2.157 1.079 1.079 0 0 0 0 2.157" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default Instagram;
